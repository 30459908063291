/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

html,
body {
  width: 100vw;
  height: 100vh;
  background-color: #e2e8f0;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.mdc-notched-outline__notch {
  border-right: none !important;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    max-width: 95vw !important;
    width: 95vw !important;
  }
}

.error-toast {
  width: 200px !important;
  margin-right: 100px !important;
  margin-left: 100px !important;
  --mdc-snackbar-container-color: red;
  --mat-snack-bar-button-color: black;
  --mdc-snackbar-supporting-text-color: white;
}

.error-toast-full {
  max-width: 100vw !important;
  margin-left: 10px !important;
  margin-right: 30px !important;
  --mdc-snackbar-container-color: red;
  --mat-snack-bar-button-color: black;
  --mdc-snackbar-supporting-text-color: white;
}

.success-toast {
  width: 200px !important;
  margin-right: 100px !important;
  margin-left: 100px !important;
  --mdc-snackbar-container-color: green;
  --mat-snack-bar-button-color: black;
  --mdc-snackbar-supporting-text-color: white;
}

.success-toast-full {
  max-width: 100vw !important;
  margin-left: 10px !important;
  margin-right: 30px !important;
  --mdc-snackbar-container-color: green;
  --mat-snack-bar-button-color: black;
  --mdc-snackbar-supporting-text-color: white;
}

.alert-toast {
  width: 200px !important;
  margin-right: 100px !important;
  margin-left: 100px !important;
  --mdc-snackbar-container-color: #b8b800;
  --mat-snack-bar-button-color-text: white !important;
  --mdc-snackbar-supporting-text-color: white;
}

.informational-toast {
  width: 200px !important;
  margin-right: 100px !important;
  margin-left: 100px !important;
  --mdc-snackbar-container-color: #2563eb;
  --mat-snack-bar-button-color-text: white !important;
  --mdc-snackbar-supporting-text-color: white;
}

.alert-toast-full {
  max-width: 100vw !important;
  margin-left: 10px !important;
  margin-right: 30px !important;
  --mdc-snackbar-container-color: #b8b800;
  --mat-snack-bar-button-color-text: white !important;
  --mdc-snackbar-supporting-text-color: white;
}

$custom-primary-palette: (
  50: #b3e5f4,
  100: #80d7ec,
  200: #4dc9e3,
  300: #1abbd9,
  400: #00b1d2,
  500: #00aacc,
  600: #009fb8,
  700: #0094a4,
  800: #008990,
  900: #007c7d,
  contrast: (
    50: #313232,
    100: #525252,
    200: #737373,
    300: #949494,
    400: #b5b5b5,
    500: #ffffff,
    600: #e7e7e7,
    700: #f8f8f8,
    800: #f9f9f9,
    900: #fafafa
  )
);

$custom-secondary-palette: (
  50: #ffd4bf,
  100: #ffb699,
  200: #ff9973,
  300: #ff7d4d,
  400: #ff6126,
  500: #ff7733,
  600: #ff5c1a,
  700: #ff4000,
  800: #ff3300,
  900: #ff2600,
  contrast: (
    50: #fafafa,
    100: #fafafa,
    200: #fafafa,
    300: #fafafa,
    400: #fafafa,
    500: #fafafa,
    600: #fafafa,
    700: #f8f8f8,
    800: #f9f9f9,
    900: #fafafa
  )
);

$custom-primary: mat.define-palette($custom-primary-palette);
$custom-accent: mat.define-palette($custom-secondary-palette);
$custom-warn: mat.define-palette(mat.$red-palette, A200, A100, A400);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn
    )
  )
);

@include mat.all-component-themes($custom-theme);

.table-headers {
  background: #ff7733 !important;
  color: #fff !important;
}

.mat-mdc-dialog-content {
  max-height: initial !important;
}

.mat-expansion-panel-header {
  background-color: #ffeee6;
}

.mat-expansion-panel-header-title {
  color: #ff5500 !important;
}

.mat-expansion-panel-header:hover {
  background-color: #ffeee6;
}

.mat-expansion-panel-header.mat-expanded {
  background-color: #ffeee6 !important;
}

.mat-danger {
  background-color: #dc2626;
  color: #fff;
}

.datepicker-bg {
  background: #ffffff;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px;
}

.mdc-floating-label--required {
  color: #dc2626;
}

@layer components {
  .bidvest-btn {
    @apply bg-white text-primary-orange font-medium py-4 px-4 rounded;
    @apply hover:bg-primary-orange/10;
    @apply focus:outline-none focus:ring-2 focus:ring-primary-orange focus:ring-opacity-50;
    @apply flex flex-row justify-center items-center;
  }

  .bidvest-flat-btn {
    @apply bg-primary-orange text-white font-medium py-4 px-4 rounded;
    @apply hover:bg-orange-600;
    @apply focus:outline-none focus:ring-2 focus:ring-primary-orange focus:ring-opacity-50;
    @apply flex flex-row justify-center items-center;
  }

  .bidvest-stroked-btn {
    @apply bg-white border-primary-orange border-[1px] text-primary-orange font-medium py-4 px-4 rounded;
    @apply hover:bg-slate-50;
    @apply focus:outline-none focus:ring-2 focus:ring-primary-orange focus:ring-opacity-50;
    @apply flex flex-row justify-center items-center;
  }
}
